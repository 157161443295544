import { useCallback, useEffect, useRef, useState } from 'react';
import logo from './white.png';
import './App.css';
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import timezone from 'dayjs/plugin/timezone.js'
dayjs.extend(utc)
dayjs.extend(timezone)
// init | creating | created

function App() {
  const [serverList, setServerList] = useState([]);
  const [creating, setCreate] = useState('init');
  const [loading, setLoading] = useState(true);
  const oldServers = useRef([]);
  let intervalId = useRef()
  let statusId = useRef()

  const handleSessionInformation = useCallback(async () => {
    const response = await fetch("/session/available");
    const { data } = await response.json() ?? {};

    const { cirrusServer } = data?.find(e => e.label === 'free') || {};
    const { servers } = data?.find(e => e.label === "servers") || {};

    if (servers) {
      let serverLists =[]
      serverLists = servers.map(server => ({
        ...server,
        free: server.address === cirrusServer?.address,
      }));
      const serviceName = localStorage.getItem('serviceName')
      if(serviceName) {
        serverLists = [...serverLists, {
          status: 'Launching'
        }]
      }

      if (!arraysEqual(serverLists, oldServers.current)) {
        setServerList(serverLists);
        oldServers.current = serverLists;
      }
    }

    
  }, []);

  useEffect(() => {
    handleSessionInformation()
    setLoading(false);
    intervalId.current = setInterval(handleSessionInformation, 5000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [handleSessionInformation]);

  const checkServiceStatus = useCallback(async()=> {
    const serviceName = localStorage.getItem('serviceName')
    if(serviceName) {
      const {services} = await(await fetch(`/describe/${serviceName}`)).json() ?? {}
      if(services && services[0]?.runningCount > 0) {
        setCreate('created')
        localStorage.removeItem('serviceName')
      }
    }

  },[])

  const handleCreateSession = useCallback(async()=>{
    setCreate('creating')
    const next = await (await fetch("/scale/create")).json()

    if(next && next?.service?.service && next?.service?.service?.serviceName) {
      localStorage.setItem('serviceName',next?.service?.service?.serviceName)
      const newServerList = [...serverList, {
        status: 'Launching'
      }]

      if (!arraysEqual(newServerList, oldServers.current)) {
        setServerList(newServerList);
        oldServers.current = newServerList;
      }
      await checkServiceStatus()
    }
  },[serverList,checkServiceStatus])

  

  useEffect(()=> {
    if(creating === 'created') return
    statusId.current = setInterval(checkServiceStatus, 5000);

    return () => {
      clearInterval(statusId.current);
    };
  },[creating,checkServiceStatus])


  const creatingText = creating ? "Session Created" : "Create Session"
  if(loading) {
    return (
      <Container className="App">
         <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='loadingContainer'>
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
       
      </Container>
    )
  }
  return (
    <Container className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='buttonContainer'>
       
          <>
          <button disabled={creating === 'created'} onClick={async () => handleCreateSession()}>{creatingText}</button>
          <button disabled={creating !== 'created'} onClick={async () => {
              window.location.href = "/connect";
          }}>Join Session</button>
          </>
        
      </div>
      <div className='cardContainer'>
      {serverList && serverList.map((item,index)=> {
        const datetime = dayjs(item.lastRedirect)
        const Component =({children}) => (
        
            <div className='card' key={ index + 'instance'}>
            <p>Server {index + 1}</p>
            {item?.lastRedirect ?  <p>Last played: {datetime.tz('Asia/Dubai').format('DD-MM HH:mm')}</p> : <p>&nbsp;</p>}
            {children}
            </div> 
        )
        console.log("item", item);
        if (item.free && item.serviceName) {
          return (<Component>
            <a href={`https://${item.serviceName}.${item.customDomain}?hoveringMouse`} target='_blank' className='App-link button' rel='noreferrer'>Play</a>
          </Component>)
        }
        return (
          <Component>
            <span className='busy'>{item.status ?? 'Reserved'}</span> 
          </Component>
        )
          })}
      </div>
    </Container>
  );
}

const Container = styled.div`
display: flex;
flex-direction:column;
gap:36px;
.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill,320px);
  align-items: center;
  gap: 20px;
  justify-content: center;

}
  text-align: center;
  .buttonContainer {
    display: flex;
    width: 100%;
    button {
      width: 200px;
    }
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
  .busy {
    color: rgb(251, 111, 67);
    cursor: not-allowed;

  }
  .button,
  button {
    border-radius: 8px;
    border: none;
    width: 153px;
    padding: 8px 24px;
    line-height: 24px;
    color: #FFFFFF;
    font-size: 18px;  
    background-color: #1CCA7D;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .App-logo {
    height: 20vmin;
    pointer-events: none;
  }


  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    text-decoration: none;
    color: #FFFFFF;
  }

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
`
function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }

  return true;
}


export default App;